import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModuleByStep, getAllModules } from '../../../actions/moduleActions';
import { getAppointmentByPatient } from '../../../actions/patientActions';
import '../../../index.css';
import '../../../css/patient_profile.css';
import { useTranslation } from 'react-i18next';
import ContactHelp from '../ContactHelp';
import NavigationPatient from '../NavigationPatientHeader';
import { ButtonNavBar } from '../buttonNavBar';
import AppointmentComplete from '../App_Four_popup';
const m4Image =
  'https://abbott-dlp-sb.s3.ap-southeast-1.amazonaws.com/modules/rewards/m4-completed.png';

function ActionModule4ActionCompletionPage({
  btnColor,
  image,
  btnText,
  background,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.fontR);
  const { module } = useSelector((state) => state.moduleR);
  const [showModalPopup, setShowModalPopup] = useState(false);

  useEffect(() => {
    dispatch(getAllModules({ language }));
  }, [language, dispatch]);

  const urlParams = new URLSearchParams(window.location.search);
  const step = urlParams.get('step');
  const patientId = urlParams.get('patientId');

  useEffect(() => {
    if (step) {
      dispatch(
        getModuleByStep({ step, language: language ? language : 'malay' })
      );
    }
  }, [dispatch, language]);

  useEffect(() => {
    const patient_Number = localStorage.getItem('patient_Number');
    // console.log('step', step);
    if (patient_Number) {
      dispatch(getAppointmentByPatient({ phoneNumber: patient_Number }));
    }
  }, [dispatch]);

  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>
      <AppointmentComplete
        showModalPopup={showModalPopup}
        t={t}
        patientId={patientId}
      />

      <div className="main-body-height">
        <div className="pb-3 position-relative">
          <img
            src={module ? module.photoUrl : ''}
            style={{
              width: '100%',
              height: '12rem',
              objectFit: 'cover',
            }}
            alt="header-img"
          />

          <div className="header-text-render mx-4">
            <div className="trial-header-text mb-2">
              {t('Registered_Patient')}
            </div>
            <div className="main-header-text">
              {module && module.titleSecond}
            </div>
          </div>
        </div>

        <div>
          <div className="mx-3">
            <div className="sv_progress_bar_block">
              <div className="green-background"></div>
              <div className="green-background mx-3"></div>
              <div className="green-background"></div>
            </div>

            <center className="my-3 mx-3">
              <img src={m4Image} alt="moduleImage" />

              <div className="py-3">{t('congratulationOnCompletingM4')}</div>
              <div>{t('clickTheLinkBelow')}</div>

              <div className="py-3">
                <a
                  className="text-underline "
                  style={{ color: 'blue' }}
                  href="https://drive.google.com/file/d/11jmnXczuk6jrzWkuaTEW0RMTtQWzvTQw/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('viewMealPlan')}
                </a>
              </div>

              <button
                onClick={() => {
                  setShowModalPopup(true);
                }}
                type="button"
                className="custom-primary-button mt-3"
              >
                {t('proceed')}
              </button>
            </center>
          </div>
        </div>
      </div>

      <div className="">
        <ButtonNavBar />
        <ContactHelp />
      </div>
    </div>
  );
}

export default ActionModule4ActionCompletionPage;
