import React from 'react';
import { useHistory } from 'react-router-dom';
import arrow from '../../images/DLP_revamp_asset/Icon/arrow.png';
import dlplogo from '../../images/DLP_revamp_asset/logo-header/color-logo-header.png';
import '../../index.css';

const NavigationPatient = () => {
  const history = useHistory();

  const handleOnClick = () => {
    history.goBack();
  };

  return (
    <div className="main-body-content pt-2">
      <div className="header-wrapper">
        <div onClick={handleOnClick} style={{ cursor: 'pointer' }}>
          <img style={{ height: '1.5rem' }} src={arrow} alt="arrow" />
        </div>
        <div>
          <img style={{ height: '3rem' }} src={dlplogo} alt="dlplogo" />
        </div>
      </div>
    </div>
  );
};

export default NavigationPatient;
