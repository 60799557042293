import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModuleByStep } from '../../../actions/moduleActions';
import '../../../index.css';
import '../../../css/patient_profile.css';
import { useTranslation } from 'react-i18next';
import ContactHelp from '../ContactHelp';
import NavigationPatient from '../NavigationPatientHeader';
import { ButtonNavBar } from '../buttonNavBar';
import completion from '../../../images/DLP_revamp_asset/action-completion-img.png';
import { Link } from 'react-router-dom';

const textJson = {
  1: {
    english: {
      text: 'You’ve completed all the tasks for the week.',
      text2: 'Well done in trying to be healthy!',
    },
    malay: {
      text: 'Anda telah menyelesaikan semua tugasan untuk minggu ini. Syabas dalam usaha untuk menjadi sihat!',
    },
  },
  2: {
    english: {
      text: 'Learn how to turn your wishlist above into reality with S.M.A.R.T goals. Continue to follow our weekly module series and attend your virtual consultations with our dietitians for guidance and advice.',
    },
    malay: {
      text: 'Pelajari cara untuk merealisasikan senarai hasrat anda di atas dengan matlamat S.M.A.R.T. Teruskan mengikuti siri modul mingguan kami dan hadiri perundingan maya dengan pakar diet kami untuk panduan dan nasihat.',
    },
  },

  4: {
    english: {
      text: 'Thank you for completing this reflection.',
    },
    malay: {
      text: 'Terima kasih kerana melengkapkan refleksi ini.',
    },
  },
  default: {
    english: {
      text: 'You’ve completed all the tasks for the week.',
      text2: 'Well done in trying to be healthy!',
    },
    malay: {
      text: 'Anda telah menyelesaikan semua tugas untuk modul ini.',
      text2: 'Tahniah atas usaha anda dalam mencuba untuk sihat!',
    },
  },
};

function ActionCompletionPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.fontR);
  const { module } = useSelector((state) => state.moduleR);
  const { patient } = useSelector((state) => state.patientR);
  const urlParams = new URLSearchParams(window.location.search);
  const step = urlParams.get('step');
  const length = Number(urlParams.get('questions'));

  useEffect(() => {
    if (step) {
      dispatch(
        getModuleByStep({ step, language: language ? language : 'malay' })
      );
    }
  }, [dispatch, language]);

  const mappedArray =
    length > 1 ? Array.from({ length }, (_, index) => index + 1) : [];

  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>

      <div className="main-body-height">
        <div className="pb-3 position-relative">
          <img
            src={module ? module.photoUrl : ''}
            style={{
              width: '100%',
              height: '12rem',
              objectFit: 'cover',
            }}
            alt="header-img"
          />

          <div className="header-text-render mx-4">
            <div className="trial-header-text mb-2">
              {module?.step < 3 ? t('Trial_Module') : t('Registered_Patient')}
            </div>
            <div className="main-header-text">
              {module && module.titleSecond}
            </div>
          </div>
        </div>

        <div className="mx-3">
          {step === '8' ? null : (
            <div className="sv_progress_bar_block">
              <div className="green-background"></div>
              <div className="green-background mx-3"></div>
              <div className="green-background"></div>
            </div>
          )}

          {step === '1' ? (
            <div className="pt-3 fw-bold">
              {language === 'english'
                ? 'Complete your personal lifestyle check'
                : 'Lengkapkan pemeriksaan gaya hidup peribadi anda'}
            </div>
          ) : null}

          <div className="sv_progress_bar_block mt-3">
            {mappedArray.map((index) => {
              return (
                <div
                  key={index}
                  className={
                    mappedArray.length === index
                      ? 'green-background'
                      : 'gray-background me-2'
                  }
                ></div>
              );
            })}
          </div>

          <div>
            <center>
              <img
                src={completion}
                style={{ width: '10rem', padding: '1rem' }}
                alt="success"
              />
            </center>
            <div className="text-center">
              <div className="mx-5 fw-bold">
                {textJson[step] ? (
                  <>
                    <div>{textJson[step][language].text}</div>
                    <div>
                      {textJson[step][language].text2 &&
                        textJson[step][language].text2}
                    </div>
                  </>
                ) : (
                  <>
                    <div>{textJson['default'][language].text}</div>
                    <div>{textJson['default'][language].text2}</div>
                  </>
                )}
              </div>

              <Link
                to={
                  patient?.public_registered_user && !patient?.doctorId
                    ? `/patient/public/home`
                    : '/patient/learn module/main'
                }
              >
                <button className="custom-primary-button mt-4">
                  {t('Okay')}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <ButtonNavBar />
        <ContactHelp />
      </div>
    </div>
  );
}

export default ActionCompletionPage;
