import React from 'react';
// import Language from './Language'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import partnerLogo from '../images/logo/partner-logo.png';

function Languages({ hideLanguage }) {
  const dispatch = useDispatch();

  function onChangeSmallFont() {
    localStorage.setItem('dlp-font-size', 'smallfont');

    dispatch({ type: 'SMALL_FONT' });
  }

  function onChangeBigFont() {
    localStorage.setItem('dlp-font-size', 'bigfont');

    dispatch({ type: 'BIG_FONT' });
  }

  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    dispatch({ type: 'CHANGE_LANGUAGE', payload: lng });
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  const { language, fontSize } = useSelector((state) => state.fontR);
  // console.log('language', language);

  return (
    <header className="intro-header-navbar">
      <div className="intro-header-navbar-body">
        <div className="d-flex intro-header-navbar-language">
          {hideLanguage ? null : (
            <>
              <p
                onClick={() => {
                  localStorage.setItem('language', 'english');
                  changeLanguage('en');
                }}
                style={{ color: language === 'english' ? '#b5893f' : '' }}
              >
                ENG
              </p>
              <p
                onClick={() => {
                  localStorage.setItem('language', 'malay');
                  changeLanguage('my');
                }}
                style={{ color: language === 'malay' ? '#b5893f' : '' }}
              >
                BM
              </p>
            </>
          )}
        </div>
        <div className="d-flex intro-header-navbar-fontsize">
          <p
            onClick={() => onChangeSmallFont()}
            style={{ color: fontSize === 'smallfont' ? '#b5893f' : '' }}
          >
            aA
          </p>
          <p
            onClick={() => onChangeBigFont()}
            style={{ color: fontSize === 'bigfont' ? '#b5893f' : '' }}
          >
            aA
          </p>
        </div>
      </div>
      <div>
        <img
          src={partnerLogo}
          alt="partnerLogo"
          style={{
            width: '100%',
            objectFit: 'contain',
            marginTop: '2rem',
            padding: '0 0.7rem',
          }}
        />
      </div>
    </header>
  );
}

export default Languages;
