import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getModuleByStep,
  getModuleQuestionsByType,
} from '../../../actions/moduleActions';
import '../../../index.css';
import { useTranslation } from 'react-i18next';
import ContactHelp from '../ContactHelp';
import NavigationPatient from '../NavigationPatientHeader';
import { ButtonNavBar } from '../buttonNavBar';
import { Link } from 'react-router-dom';
import cyclingImg from '../../../images/DLP_revamp_asset/module6_q2_option1.png';

function ActionModule6SuccessPage({ history }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.fontR);
  const [tooltip, setTooltip] = useState('');
  const step = 6;

  useEffect(() => {
    if (step) {
      dispatch(getModuleByStep({ step, myType: 'action', language }));
      dispatch(getModuleQuestionsByType({ step, myType: 'action', language }));
    }
  }, [language, dispatch]);

  const { module, questions } = useSelector((state) => state.moduleR);

  useEffect(() => {
    if (questions) {
      const firstQ = questions[0];
      if (firstQ[0]) {
        setTooltip(firstQ[0]?.tooltip);
      }
    }
  }, [questions]);

  // console.log('tooltip', tooltip);

  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>

      <div className="main-body-height">
        <div className="pb-3 position-relative">
          <img
            src={module ? module.photoUrl : ''}
            style={{
              width: '100%',
              height: '12rem',
              objectFit: 'cover',
            }}
            alt="header-img"
          />

          <div className="header-text-render mx-4">
            <div className="trial-header-text mb-2">
              {t('Registered_Patient')}
            </div>
            <div className="main-header-text">
              {module && module.titleSecond}
            </div>
          </div>
        </div>

        <div className="main-body-height mx-3">
          <div className="mx-3">
            <div className="sv_progress_bar_block">
              <div className="green-background"></div>
              <div className="green-background mx-3"></div>
              <div className="green-background"></div>
            </div>

            <div className="mt-4">
              <center>
                <img
                  src={cyclingImg}
                  style={{ width: '16rem', objectFit: 'contain' }}
                  alt="success"
                />
              </center>
              <div className="text-center">
                <div className="mx-3 py-4">{tooltip}</div>

                <Link
                  to={'/patient/action/completion' + window.location.search}
                >
                  <button className="custom-primary-button mt-4">
                    {t('Okay')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ButtonNavBar />
      <ContactHelp />
    </div>
  );
}

export default ActionModule6SuccessPage;
