import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { tokenConfig } from '../../actions/actionHelperFunc';

const PrivacyPage = ({ history }) => {
  const { t } = useTranslation();

  const [consent, setConsent] = useState(false);
  const [phoneNumber] = useState(localStorage.getItem('patient_Number'));
  const [inputError, setInputError] = useState(null);

  const handleClose = (e) => {
    e.preventDefault();

    const data = {
      phoneNumber: phoneNumber,
      consent: consent,
    };

    axios
      .post('/api/patient/updatePatient', data, tokenConfig())
      .then((res) => {
        history.push('/patient/ftr_doctor_info');
      })
      .catch((err) => {
        setInputError(err.response.data.error);
      });
  };

  return (
    <div>
      <form onSubmit={handleClose}>
        <Modal
          show={true}
          backdrop="static"
          keyboard={false}
          centered
          className="dc-disclaimer-consent-modal pt-disclaimer-consent-modal"
          style={{ opacity: '1' }}
        >
          <Modal.Header>
            <Modal.Title> {t('disclaimer')} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row dc-disclaimer-consent-modal-top-body pt-disclaimer-consent-modal-top-body">
              <div className="col">
                <p>
                  {t('By_Using_The_Web_Based_App')}
                  <br />
                  {t('You_Agree_For_Practices')}
                  <br />
                  {t('This_dalam')}
                  <a
                    href="https://www.my.abbott/privacy-policy.html"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: '#b5893f',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }}
                  >
                    "{t('Privacy_Notice')}" & "{t('consentNotice')}"
                  </a>
                </p>
              </div>
            </div>
            <form id="dc-close-modal-tnc">
              <div className="row dc-disclaimer-consent-modal-bot-body pt-disclaimer-consent-modal-bot-body">
                <div className="col">
                  <div className="d-flex">
                    <Form.Check
                      value={consent}
                      onChange={(e) => setConsent(!consent)}
                      aria-label="option 1"
                      required
                    />
                    <p>
                      {t('I_Have_Read')}{' '}
                      <a
                        href="https://www.my.abbott/privacy-policy.html"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#b5893f',
                          textDecoration: 'underline',
                        }}
                      >
                        "{t('Privacy_Notice')}" & "{t('consentNotice')}"
                      </a>
                      " {t('And_Consent_To_Process_Data')}
                    </p>
                  </div>
                  <div className="d-flex">
                    <Form.Check aria-label="option 2" />
                    <p>{t('I_Would_Also_Like_To_Receive_Comm')}</p>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer style={{ border: 'none' }}>
            <Button form="dc-close-modal-tnc" type="submit" variant="secondary">
              {t('Proceed')}
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default PrivacyPage;
