import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TrailCompletionModal = ({ show, patientId, setShowModal }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <div className="mx-3">
          <Modal.Body className="text-center">
            <div className="patient-header-text mt-4" style={{ color: '#000' }}>
              {t('You have finished the trial modules of DLP')}!​
            </div>
            <div className="py-4" style={{ lineHeight: '1rem' }}>
              {t('The remaining modules')}
            </div>
            <i>*{t('All information recorded')}.</i>
          </Modal.Body>
          <div className="pb-3 mx-3">
            <button
              className="custom-primary-button mt-2"
              style={{ lineHeight: '1.2rem' }}
              onClick={() =>
                (window.location.href = '/patient/ftr_doctor_info')
              }
            >
              <div>{t('My Healthcare Provider is a')} </div>
              <div>{t('DLP Advocate')}. </div>
              <div>{t('Sign up now')}.</div>
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="custom-transparent-button mt-2"
            >
              {t('Close')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TrailCompletionModal;
