import React from 'react';
import divider from '../../images/DLP_revamp_asset/Icon/divider.png';
import WhatsApp from '../../images/DLP_revamp_asset/Icon/contact-us-navbar.png';

import { useTranslation } from 'react-i18next';
import ReactWhatsapp from 'react-whatsapp';

import '../../css/contact-footer.css';

const ContactHelp = ({ hideTitle }) => {
  const { t, i18n } = useTranslation();

  const getLang = () => {
    if (i18n.language === 'en' || i18n.language === 'english') {
      return 'en';
    } else {
      return 'my';
    }
  };

  return (
    <>
      <div className={`main-footer ${getLang()}`}>
        <ReactWhatsapp
          number="+601154085382"
          style={{ border: 'none', background: 'transparent', width: '100%' }}
        >
          <div className={`main-footer ${getLang()}`}>
            <div>
              <img
                id="WhatsApp"
                src={WhatsApp}
                alt="whatsapp-logo"
                size={'2rem'}
              />
            </div>
            <div className="helpdesk-footer py-2">
              <div className="footer-info">
                <h1>{t('helpdesk')}?</h1>
              </div>
              <div className="mx-2">
                <img id="divider" src={divider} alt="divider" size={'2rem'} />
              </div>
              <div className="footer-info-business text-start">
                <b>{t('techEnquiry')}</b>
                <b>{t('operationHour')}</b>
              </div>
            </div>
          </div>
        </ReactWhatsapp>
      </div>
    </>
  );
};

export default ContactHelp;
