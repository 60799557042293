// eslint-disable-next-line no-unused-vars
import React, { Suspense, lazy, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import VideoWhereby from './components/video/VideoWhereby';
import PrivacyPage from './view/patient/PrivacyPage';
import PublicRegistrationPage from './view/patient/PublicRegistrationPage';
import PatientPublicUserHomePage from './view/patient/PatientPublicUserHomePage';
import PatientPublicLandingPage from './view/patient/PatientPublicLandingPage';
import SectionCompletionPage from './view/patient/Learn Module/SectionCompletionPage';
import VideoCompletionPage from './view/patient/Learn Module/VideoCompletionPage';
import ActionCompletionPage from './view/patient/Learn Module/ActionCompletionPage';
import ActionModule4ActionCompletionPage from './view/patient/Learn Module/ActionModule4ActionCompletionPage';
import PublicUserPreviewModulePage from './view/patient/PublicUserPreviewModulePage';
import ActionModule1 from './view/patient/Learn Module/ActionModule1';
import ActionModule2 from './view/patient/Learn Module/ActionModule2';
import ActionModule5 from './view/patient/Learn Module/ActionModule5';
import ActionModule6 from './view/patient/Learn Module/ActionModule6';
import ActionModule6SuccessPage from './view/patient/Learn Module/ActionModule6SuccessPage';
import ActionModule9ba from './view/patient/Learn Module/ActionModule9ba';

const Registration = lazy(() => import('./view/dietitian/registration'));
const PatientDatabase = lazy(() => import('./view/dietitian/patientDatabase'));
const OTPNumber = lazy(() => import('./view/dietitian/OTPNumber'));
const FtrMain = lazy(() => import('./view/dietitian/ftrMain'));
const FtrPD = lazy(() => import('./view/dietitian/ftrPD'));
const FtrWD = lazy(() => import('./view/dietitian/ftrWd'));
const chkApp = lazy(() => import('./view/dietitian/chkApp'));
const PatientDetail = lazy(() => import('./view/dietitian/PatientProfile'));
const PatientProfile = lazy(() => import('./view/dietitian/PatientProfile'));
const dietitianReport = lazy(() => import('./view/dietitian/dietitianReport'));
const dietitianReportdev = lazy(() =>
  import('./view/dietitian/dietitianReportdev')
);
const PatientProgress = lazy(() => import('./view/dietitian/PatientProgress'));
const User_profile = lazy(() => import('./view/dietitian/user_profile'));
const Medical_Profile = lazy(() => import('./view/dietitian/medical_profile'));
const HelpDietitian = lazy(() => import('./view/dietitian/help'));

const doctorIntroPageFirst = lazy(() => import('./view/doctor/doctorIntro'));
const doctorRegisterPage = lazy(() =>
  import('./view/doctor/doctorRegisterPage')
);
const doctorOtp = lazy(() => import('./view/doctor/doctorOtp'));
const doctorPolicy = lazy(() => import('./view/doctor/doctorPolicyPage'));
const doctorfirstRegMenu = lazy(() => import('./view/doctor/doctorCodeGen'));
const doctorfirstRegPersonalDetails = lazy(() =>
  import('./view/doctor/doctorPersonalDetail')
);
const doctorfirstRegWorkDetails = lazy(() =>
  import('./view/doctor/doctorWorkDetail')
);
const doctorfirstRegSuccess = lazy(() =>
  import('./view/doctor/doctorRegSuccess')
);
const doctorHomePage = lazy(() => import('./view/doctor/doctorHomepage'));
const doctorPatientDatabase = lazy(() =>
  import('./view/doctor/doctorPatientDatabase')
);
const doctorPatientDetails = lazy(() =>
  import('./view/doctor/doctorPatientDetails')
);

const doctorPatientProfile = lazy(() =>
  import('./view/doctor/doctorPatientProfile')
);
const doctorPatientMedDetails = lazy(() =>
  import('./view/doctor/doctorPatientMedicalProfile')
);
const doctorPatientProgress = lazy(() =>
  import('./view/doctor/doctorPatientProgress')
);
const doctorDietitianReport = lazy(() =>
  import('./view/doctor/doctorDietitianReport')
);
const doctorRegPatient = lazy(() => import('./view/doctor/doctorRegPatient'));
const doctorRegPatientElig = lazy(() =>
  import('./view/doctor/doctorRegPatientElig')
);
const doctorRegPatientInfo = lazy(() =>
  import('./view/doctor/doctorRegPatientInfo')
);
const doctorRegPatientCode = lazy(() =>
  import('./view/doctor/doctorRegPatientCode')
);
const DoctorReviewLifestyleProgress = lazy(() =>
  import('./view/doctor/doctorVisit2Review')
);
const doctorPreviewNextModule = lazy(() =>
  import('./view/doctor/doctorVisit2Preview')
);
const doctorInputMedData = lazy(() =>
  import('./view/doctor/doctorVisit3InputMedData')
);
const doctorReviewEngageParticipation = lazy(() =>
  import('./view/doctor/doctorVisit3ReviewEngage')
);
const doctorReviewLifestyleCheckProgress = lazy(() =>
  import('./view/doctor/doctorVisit3ReviewLifestyleCheck')
);
const doctorReviewComment = lazy(() =>
  import('./view/doctor/doctorPatientCommentLifestyle')
);
const doctorReviewGoals = lazy(() =>
  import('./view/doctor/doctorPatientLongTermGoal')
);
const DoctorPatientComment = lazy(() =>
  import('./view/doctor/doctorPatientComment')
);
const doctorFtjPhoneType = lazy(() =>
  import('./view/doctor/doctorFtjPhoneType')
);
const doctorHelpDesk = lazy(() => import('./view/doctor/doctorHelpDesk'));

const patientIntroV2 = lazy(() => import('./view/patient/patientIntroV2'));
const PatientRegistration = lazy(() =>
  import('./view/patient/patientRegisterPage')
);

const PatientOtp = lazy(() => import('./view/patient/patientOtp'));
const patientPolicy = lazy(() => import('./view/patient/patientPolicyPage'));
const PatientFtrDoctorInfo = lazy(() =>
  import('./view/patient/patientFtrKeyDoctor')
);
const PatientFtrPersonalDetails = lazy(() =>
  import('./view/patient/patientFtrPersonalDetail')
);
const PatientFtrMedDetallsFirst = lazy(() =>
  import('./view/patient/patientFtrMedInfoFirst')
);
const PatientFtrMedDetallsSec = lazy(() =>
  import('./view/patient/patientFtrMedInfoSec')
);
const PatientFtrCloseFamily = lazy(() =>
  import('./view/patient/patientFtrAddCloseFam')
);
const PatientFtjPhoneType = lazy(() =>
  import('./view/patient/patientFtjPhoneType')
);
const appointment_details = lazy(() =>
  import('./view/patient/appointment_details')
);
const PatientSettingPage = lazy(() =>
  import('./view/patient/PatientSettingPage')
);
const records = lazy(() => import('./view/patient/records'));
const CloseFamilyEdit = lazy(() => import('./view/patient/patientEditFamily'));
const FamilyMember = lazy(() => import('./view/patient/patientFamilyMember'));
const UserProfiles = lazy(() => import('./view/patient/userProfile'));
const UserProfiless = lazy(() => import('./view/dietitian/userProfile'));
const AntrhopDetails = lazy(() => import('./view/patient/antrhopDetails'));
const MedicalProfile = lazy(() => import('./view/patient/medicalProfile'));
const Help = lazy(() => import('./view/patient/help'));
const Patient_progress = lazy(() => import('./view/patient/patient_progress'));
const Doctors_comment = lazy(() => import('./view/patient/doctors_comment'));
const doctorNote = lazy(() => import('./view/patient/patientDoctorNote'));
const ReferFriend = lazy(() => import('./view/patient/referFriend'));
const Welcome = lazy(() => import('./view/patient/Learn Module/Welcome'));
const ModulesPage = lazy(() =>
  import('./view/patient/Learn Module/ModulesPage')
);
const Video = lazy(() => import('./view/patient/Learn Module/video'));
const ReflectionModule = lazy(() =>
  import('./view/patient/Learn Module/ReflectionModule')
);
const ReflectionModule3 = lazy(() =>
  import('./view/patient/Learn Module/ReflectionModule3')
);
const ReflectionModule4 = lazy(() =>
  import('./view/patient/Learn Module/ReflectionModule4')
);
const ReflectionAnswers = lazy(() =>
  import('./view/patient/Learn Module/ReflectionAnswers')
);
const ActionModule = lazy(() =>
  import('./view/patient/Learn Module/ActionModule')
);
const ActionModule3Intro = lazy(() =>
  import('./view/patient/Learn Module/ActionModule3Intro')
);
const ActionModule3Main = lazy(() =>
  import('./view/patient/Learn Module/ActionModule3Main')
);
const ActionModule4Main = lazy(() =>
  import('./view/patient/Learn Module/ActionModule4Main')
);
const ModuleHeaderViewPage = lazy(() =>
  import('./view/patient/Learn Module/ModuleHeaderViewPage')
);
const ModuleProgress = lazy(() =>
  import('./view/patient/Learn Module/ModuleProgress')
);
const DisplayVideo = lazy(() =>
  import('./view/patient/Learn Module/DisplayVideo')
);

const DietitianIntro = lazy(() => import('./view/dietitian/dietitianintroV2'));
const ViewComment = lazy(() => import('./view/patient/ViewComment'));

const AddToHomeScreen = lazy(() => import('./view/dietitian/addTohomeScreen'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const MainLandingPage = lazy(() => import('./view/MainLandingPage'));

const ActionModule4Intro = lazy(() =>
  import('./view/patient/Learn Module/ActionModule4Intro')
);
const ActionModule7 = lazy(() =>
  import('./view/patient/Learn Module/ActionModule7')
);
const ActionModule8 = lazy(() =>
  import('./view/patient/Learn Module/ActionModule8')
);
const ActionModule9 = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9')
);
const ActionModule9a = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9a')
);
const ActionModule9b = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9b')
);
const ActionModule9c = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9c')
);
const ActionModule9d = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9d')
);
const ActionModule9e = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9e')
);

const DoctorPersonalUpdate = lazy(() =>
  import('./view/doctor/details/DoctorPersonalUpdate')
);
const DoctorWorkUpdate = lazy(() =>
  import('./view/doctor/details/DoctorWorkUpdate')
);
const DietitianProfileUpdate = lazy(() =>
  import('./view/dietitian/details/DietitianProfileUpdate')
);
const AddAvailableSlot = lazy(() =>
  import('./view/dietitian/details/AddAvailableSlot')
);

const AddressPage = lazy(() =>
  import('./view/patient/supporting-materials/AddressPage')
);
const MyRewards = lazy(() =>
  import('./view/patient/supporting-materials/MyRewards')
);
const DeliveryDetails = lazy(() =>
  import('./view/patient/supporting-materials/DeliveryDetails')
);

const MobileRoutesRenderer = () => {
  return (
    <Switch>
      <Route exact path="/dietitian/welcome" component={DietitianIntro} />
      <Route exact path="/dietitian/home" component={chkApp} />
      <Route
        exact
        path="/dietitian/HomeScreenTutorial"
        component={AddToHomeScreen}
      />
      <Route
        exact
        path="/dietitian/record/progress"
        component={PatientProgress}
      />
      <Route exact path="/dietitian/user_profile" component={UserProfiless} />
      <Route
        exact
        path="/dietitian/record/antrhop_details"
        component={AntrhopDetails}
      />
      <Route exact path="/dietitian/otp" component={OTPNumber} />
      <Route
        exact
        path="/dietitian/first_time_registration"
        component={FtrMain}
      />
      <Route
        exact
        path="/dietitian/patient_profile"
        component={PatientProfile}
      />
      <Route
        exact
        path="/dietitian/patientDatabase"
        component={PatientDatabase}
      />
      <Route exact path="/dietitian/personal_details" component={FtrPD} />
      <Route exact path="/dietitian/work_details" component={FtrWD} />
      <Route
        exact
        path="/dietitian/record/dietitian_report"
        component={dietitianReport}
      />
      <Route
        exact
        path="/dietitian/record/user_profile"
        component={User_profile}
      />
      <Route
        exact
        path="/dietitian/record/medical_profile"
        component={Medical_Profile}
      />
      <Route
        exact
        path="/dietitian/patient_profile"
        component={PatientProfile}
      />
      <Route exact path="/dietitian" component={Registration} />
      <Route exact path="/dietitian" component={chkApp} />
      <Route exact path="/dietitian_report" component={dietitianReport} />
      <Route
        exact
        path="/dietitian/dietitian_report"
        component={dietitianReportdev}
      />
      <Route exact path="/dietitian/user" component={PatientDetail} />
      {/* Patient Page */}
      <Route exact path="/patient" component={patientIntroV2} />
      <Route exact path="/patient/register" component={PatientRegistration} />
      <Route exact path="/patient/otp" component={PatientOtp} />
      <Route exact path="/patient/records" component={records} />
      <Route
        exact
        path="/patient/ftr_personal_details"
        component={PatientFtrPersonalDetails}
      />
      <Route
        exact
        path="/patient/ftr_med_Details_first"
        component={PatientFtrMedDetallsFirst}
      />
      <Route
        exact
        path="/patient/ftr_med_Details_sec"
        component={PatientFtrMedDetallsSec}
      />
      <Route
        exact
        path="/patient/ftr_add_close_fam"
        component={PatientFtrCloseFamily}
      />
      <Route
        exact
        path="/patient/ftj_phone_type"
        component={PatientFtjPhoneType}
      />
      <Route
        exact
        path="/patient/user_profile/close_family_info"
        component={CloseFamilyEdit}
      />
      <Route
        exact
        path="/patient/user_profile/close_family_info/family_member"
        component={FamilyMember}
      />
      <Route exact path="/patient/learn module/welcome" component={Welcome} />
      <Route
        exact
        path="/patient/learn_module/action"
        component={ActionModule}
      />
      <Route exact path="/patient/learn module/video" component={Video} />
      <Route
        exact
        path="/dietitian/dietitian_report"
        component={dietitianReportdev}
      />
      <Route exact path="/dietitian/user" component={PatientDetail} />
      <Route exact path="/dietitian/help" component={HelpDietitian} />
      <Route exact path="/patient/register" component={PatientRegistration} />
      <Route exact path="/patient/otp" component={PatientOtp} />
      <Route exact path="/patient/records" component={records} />
      <Route
        exact
        path="/patient/ftr_doctor_info"
        component={PatientFtrDoctorInfo}
      />
      <Route
        exact
        path="/patient/ftr_personal_details"
        component={PatientFtrPersonalDetails}
      />
      <Route
        exact
        path="/patient/ftr_med_Details_first"
        component={PatientFtrMedDetallsFirst}
      />
      <Route
        exact
        path="/patient/ftr_med_Details_sec"
        component={PatientFtrMedDetallsSec}
      />
      <Route
        exact
        path="/patient/ftr_add_close_fam"
        component={PatientFtrCloseFamily}
      />
      <Route
        exact
        path="/patient/ftj_phone_type"
        component={PatientFtjPhoneType}
      />
      <Route exact path="/patient/privacy" component={PrivacyPage} />
      <Route exact path="/patient/user profile" component={UserProfiles} />
      <Route exact path="/patient/medical_profile" component={MedicalProfile} />
      <Route exact path="/patient/antrhop details" component={AntrhopDetails} />
      <Route exact path="/patient/user_profile" component={UserProfiles} />
      <Route exact path="/patient/learn module/welcome" component={Welcome} />
      <Route
        exact
        path="/patient/learn_module/action"
        component={ActionModule}
      />
      <Route exact path="/patient/learn module/video" component={Video} />
      <Route exact path="/patient/learn module/main" component={ModulesPage} />
      <Route
        exact
        path="/patient/learn module/main/preview"
        component={ModuleHeaderViewPage}
      />
      <Route
        exact
        path="/patient/learn module/progress"
        component={ModuleProgress}
      />
      <Route
        exact
        path="/patient/learn_module/reflection/quiz"
        component={ReflectionModule}
      />
      <Route
        exact
        path="/patient/learn_module/module3/reflection/quiz"
        component={ReflectionModule3}
      />
      <Route
        exact
        path="/patient/learn_module/module3/action"
        component={ActionModule3Intro}
      />
      <Route
        exact
        path="/patient/learn_module/module3/action/main"
        component={ActionModule3Main}
      />
      <Route
        exact
        path="/patient/learn_module/module4/reflection/quiz"
        component={ReflectionModule4}
      />
      <Route
        exact
        path="/patient/learn_module/module4/action/main"
        component={ActionModule4Main}
      />
      <Route
        exact
        path="/patient/learn_module/module7/action/main"
        component={ActionModule7}
      />
      <Route
        exact
        path="/patient/learn_module/module7/action/main"
        component={ActionModule7}
      />
      <Route
        exact
        path="/patient/learn_module/module8/action/main"
        component={ActionModule8}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main"
        component={ActionModule9}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/a"
        component={ActionModule9a}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/b"
        component={ActionModule9b}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/ba"
        component={ActionModule9ba}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/c"
        component={ActionModule9c}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/d"
        component={ActionModule9d}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/e"
        component={ActionModule9e}
      />
      <Route exact path="/patient/view/answer" component={ReflectionAnswers} />
      <Route exact path="/patient/view/answer/video" component={DisplayVideo} />
      <Route exact path="/patient/learn_module/video" component={Video} />
      <Route
        exact
        path="/patient/record/appointment"
        component={appointment_details}
      />
      <Route
        exact
        path="/patient/record/user_profile"
        component={UserProfiles}
      />
      <Route
        exact
        path="/patient/record/medical_profile"
        component={MedicalProfile}
      />
      <Route
        exact
        path="/patient/record/antrhop_details"
        component={AntrhopDetails}
      />
      <Route exact path="/patient/help" component={Help} />
      <Route exact path="/patient/policy" component={patientPolicy} />
      <Route exact path="/patient_profile" component={PatientProfile} />
      <Route
        exact
        path="/patient/record/progress"
        component={Patient_progress}
      />
      <Route
        exact
        path="/patient/record/doctors comment"
        component={Doctors_comment}
      />
      <Route exact path="/patient/record/doctorNote" component={doctorNote} />
      <Route exact path="/patient/refer_friend" component={ReferFriend} />
      <Route exact path="/patient/records" component={records} />
      <Route exact path="/patient/view/comments" component={ViewComment} />
      <Route exact path="/medical_profile" component={Medical_Profile} />
      <Route exact path="/doctor" component={doctorIntroPageFirst} />
      <Route exact path="/doctor/registration" component={doctorRegisterPage} />
      <Route exact path="/doctor/register" component={doctorRegisterPage} />
      <Route exact path="/doctor/login" component={doctorRegisterPage} />
      <Route exact path="/doctor/otp" component={doctorOtp} />
      <Route exact path="/doctor/policy" component={doctorPolicy} />
      <Route exact path="/doctor/help" component={doctorHelpDesk} />
      <Route exact path="/doctor/ftr_menu" component={doctorfirstRegMenu} />
      <Route
        exact
        path="/doctor/ftr_personal"
        component={doctorfirstRegPersonalDetails}
      />
      <Route
        exact
        path="/doctor/ftr_work"
        component={doctorfirstRegWorkDetails}
      />
      <Route
        exact
        path="/doctor/ftr_success"
        component={doctorfirstRegSuccess}
      />
      <Route
        exact
        path="/doctor/ftj_phone_type"
        component={doctorFtjPhoneType}
      />
      <Route exact path="/doctor/homepage" component={doctorHomePage} />
      <Route
        exact
        path="/doctor/patient_db"
        component={doctorPatientDatabase}
      />
      <Route
        exact
        path="/doctor/patient/details"
        component={doctorPatientDetails}
      />

      <Route
        exact
        path="/doctor/patient/user_profile"
        component={doctorPatientProfile}
      />
      <Route
        exact
        path="/doctor/patient/medical_profile"
        component={doctorPatientMedDetails}
      />
      <Route
        exact
        path="/doctor/patient/dietitian_report"
        component={doctorDietitianReport}
      />
      <Route
        exact
        path="/doctor/patient/review_lifestyle_and_progress"
        component={DoctorReviewLifestyleProgress}
      />
      <Route
        exact
        path="/doctor/patient/preview_next_module"
        component={doctorPreviewNextModule}
      />
      <Route
        exact
        path="/doctor/patient/input_med_data"
        component={doctorInputMedData}
      />
      <Route
        exact
        path="/doctor/patient/review_patient_engangement_participation"
        component={doctorReviewEngageParticipation}
      />
      <Route
        exact
        path="/doctor/patient/review_lifestyle_check_and_progress"
        component={doctorReviewLifestyleCheckProgress}
      />
      <Route
        exact
        path="/doctor/patient/review_lifestyle_check_and_progress/comment"
        component={doctorReviewComment}
      />
      <Route
        exact
        path="/doctor/patient/review_lifestyle_check_and_progress/goals"
        component={doctorReviewGoals}
      />
      <Route
        exact
        path="/doctor/patient/progress"
        component={doctorPatientProgress}
      />
      <Route
        exact
        path="/doctor/patient/comment"
        component={DoctorPatientComment}
      />
      <Route
        exact
        path="/doctor/register_patient/elig"
        component={doctorRegPatientElig}
      />
      <Route
        exact
        path="/doctor/register_patient/details"
        component={doctorRegPatient}
      />
      <Route
        exact
        path="/doctor/register_patient/info"
        component={doctorRegPatientInfo}
      />
      <Route
        exact
        path="/doctor/register_patient/code"
        component={doctorRegPatientCode}
      />
      <Route exact path="/video/call" component={VideoWhereby} />
      <Route exact path="/privacy/policy" component={PrivacyPolicy} />
      <Route
        exact
        path="/doctor/update/personal"
        component={DoctorPersonalUpdate}
      />
      <Route exact path="/doctor/update/work" component={DoctorWorkUpdate} />
      <Route
        exact
        path="/dietitian/update/profile"
        component={DietitianProfileUpdate}
      />
      <Route exact path="/dietitian/add/slot" component={AddAvailableSlot} />
      <Route exact path="/" component={MainLandingPage} />
      <Route exact path="/address" component={AddressPage} />
      <Route exact path="/rewards" component={MyRewards} />
      <Route exact path="/deliveryDetails" component={DeliveryDetails} />
      <Route
        exact
        path="/patient/public/register"
        component={PublicRegistrationPage}
      />
      <Route
        exact
        path="/patient/public/home"
        component={PatientPublicUserHomePage}
      />
      <Route
        exact
        path="/patient/public/landing"
        component={PatientPublicLandingPage}
      />
      <Route
        exact
        path="/patient/learn_module/reflection/completion"
        component={SectionCompletionPage}
      />
      <Route
        exact
        path="/patient/video/completion"
        component={VideoCompletionPage}
      />
      <Route
        exact
        path="/patient/action/completion"
        component={ActionCompletionPage}
      />
      <Route
        exact
        path="/patient/module4/completion"
        component={ActionModule4ActionCompletionPage}
      />
      <Route
        exact
        path="/patient/user-profile"
        component={PatientSettingPage}
      />
      <Route
        exact
        path="/public/patient/preview_module"
        component={PublicUserPreviewModulePage}
      />
      <Route exact path="/patient/action/module1" component={ActionModule1} />
      <Route exact path="/patient/action/module2" component={ActionModule2} />
      <Route exact path="/patient/action/module5" component={ActionModule5} />
      <Route exact path="/patient/action/module6" component={ActionModule6} />
      <Route
        exact
        path="/patient/action/module6/success"
        component={ActionModule6SuccessPage}
      />

      <Route render={() => <Redirect to={{ pathname: '/' }} />} />
    </Switch>
  );
};

export default MobileRoutesRenderer;
