import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModuleByStep } from '../../../actions/moduleActions';
import { Link } from 'react-router-dom';
import Message from '../../../components/Message';
import RevampPageLayout from '../../../components/RevampPageLayout';

function ActionModule9ba() {
  const dispatch = useDispatch();
  const { module, error } = useSelector((state) => state.moduleR);
  const [patientId, setPatientId] = useState('');
  const { language } = useSelector((state) => state.fontR);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const patientId = urlParams.get('patientId');

    if (patientId) {
      setPatientId(patientId);
    }
    dispatch(getModuleByStep({ step: 9, language }));
  }, [language, dispatch]);

  return (
    <RevampPageLayout
      headerTitle={'MY PLEDGE TO MAKE LASTING CHANGES'}
      hideRegisteredWord={true}
      module={module}
      smallText={true}
    >
      {module ? (
        <div className="mx-3">
          <div
            className="mt-4"
            style={{
              background: '#F9DF81',
              borderRadius: '15px',
              padding: '1rem',
            }}
          >
            {language === 'english' ? (
              <div>
                <div className="pb-2">
                  This is the beginning of a brighter future for you.{' '}
                </div>
                <p>
                  As you get ready to move beyond the Diabetes Lifestyle
                  Programme, spend some time reflecting on your journey so far.
                  Take the pledge that you will continue to strive to live well
                  & healthily with diabetes.
                </p>
              </div>
            ) : (
              <div>
                <div className="pb-2">
                  Ini adalah permulaan masa depan anda yang lebih cerah.
                </div>
                <p>
                  Sambil anda sedia untuk melangkah ke luar Program Gaya Hidup
                  Diabetes, luangkan sedikit masa untuk merenung perjalanan anda
                  setakat ini. Berjanji bahawa anda akan terus berusaha untuk
                  hidup dengan baik dan sihat dengan diabetes.
                </p>
              </div>
            )}
          </div>

          <h1
            style={{
              color: '#b5893f',
              background: '#F9DF81',
              borderRadius: '10px',
              padding: '1rem',
            }}
            className="py-3 fw-bold text-center mt-4"
          >
            {language === 'english'
              ? 'Are you ready to continue this journey?'
              : 'Adakah anda bersedia untuk meneruskan perjalanan ini?'}
          </h1>

          <div className="mt-5">
            <Link
              to={`/patient/learn_module/module9/action/main/c?step=9&&patientId=${patientId}`}
            >
              <button className="custom-primary-button mb-3" type="submit">
                {language === 'english' ? 'I am ready' : 'Saya Bersedia'}
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className="py-2 mx-3">
            <Message variant="danger" fullWidth="true">
              Server Error
            </Message>
          </div>
        </div>
      )}
    </RevampPageLayout>
  );
}

export default ActionModule9ba;
