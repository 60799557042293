import {
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  SET_LOADING,
  CLEAR_ERROR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  LOGOUT_SUCCUSS,
  LOGOUT_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from './types';

const initialState = {
  loading: false,
  user: null,
  success: null,
};

const authReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        success: null,
      };

    // user login and registration success
    case SEND_OTP_SUCCESS:
      console.log('action.payload', action.payload);
      return {
        ...state,
        loading: false,
        error: false,
        otp: action.payload.otp,
        patientNotRegistered: action.payload.patientNotRegistered || null,
      };

    case VERIFY_OTP_SUCCESS:
      localStorage.setItem('dlp-user-token', action.payload.token);
      return {
        user: action.payload.user,
        loading: false,
        error: false,
        verify: true,
      };

    case REGISTER_SUCCESS:
      return {
        user: action.payload,
        loading: false,
        error: false,
        success: true,
      };

    // user auth error and logout
    case SEND_OTP_FAIL:
    case VERIFY_OTP_FAIL:
    case LOGOUT_FAIL:
    case REGISTER_FAIL:
      return {
        loading: false,
        verify: false,
        success: false,
        error: action.payload,
      };

    case LOGOUT_SUCCUSS:
      localStorage.removeItem('dlp-user-token');
      return {
        loading: false,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
