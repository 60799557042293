const ModalPopupOne = ({
  showPopUpAction,
  setShowPopUpAction,
  feedback,
  onComplete,
  t,
  currentStep,
  setCurrentStep,
  lastQuestion,
  language,
}) => {
  function handleClose() {
    setShowPopUpAction(false);
    setCurrentStep(currentStep + 1);
  }

  return (
    <div className="mt-3">
      <center>
        <img
          src={`https://mystatic123.s3.ap-southeast-1.amazonaws.com/modules/module1/1.${
            currentStep + 1
          }.jpeg`}
          style={{
            width: '100%',
            height: '15rem',
            objectFit: 'contain',
          }}
          alt="success"
        />
      </center>
      <div className="mt-3 fw-bold">
        {lastQuestion ? (
          <div>
            <div className="ms-4">
              {feedback.feedback && feedback.feedback[0]}
            </div>
            {feedback.feedback &&
              feedback.feedback.map((each, idx) => {
                if (idx > 0) {
                  return (
                    <ul
                      className="ms-3"
                      key={idx}
                      style={{ paddingRight: '5px' }}
                    >
                      <li>{each} </li>
                    </ul>
                  );
                }
              })}
          </div>
        ) : (
          feedback.feedback &&
          feedback.feedback.map((each, idx) => {
            return (
              <ul key={idx} style={{ paddingRight: '5px' }}>
                <li>{each} </li>
              </ul>
            );
          })
        )}

        <div className="mx-3 mb-3">
          <button
            className="custom-primary-button mt-2"
            onClick={lastQuestion ? onComplete() : handleClose()}
          >
            {t('ContinueM')}
          </button>
        </div>
      </div>{' '}
    </div>
  );
};

export default ModalPopupOne;
