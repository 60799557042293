import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModuleByStep } from '../../../actions/moduleActions';
import '../../../index.css';
import '../../../css/patient_profile.css';
import { useTranslation } from 'react-i18next';
import ContactHelp from '../ContactHelp';
import NavigationPatient from '../NavigationPatientHeader';
import { ButtonNavBar } from '../buttonNavBar';
import success from '../../../images/DLP_revamp_asset/Icon/great-job-icon.png';
import { Link } from 'react-router-dom';

const textJson = {
  2: {
    english: {
      text: 'Now that you have identified what you value and with that in mind, let us proceed to watch the video on how to "Set S.M.A.R.T Goals for Effective Lifestyle Change"',
      image:
        'https://abbott-dlp-sandbox.s3.ap-southeast-1.amazonaws.com/modules/images_dlp/module2/reflection/section3/q3.webp',
    },
    malay: {
      text: 'Setelah anda mengenal pasti apa yang anda hargai dan dengan itu di fikiran, mari kita teruskan untuk menonton video tentang cara "Menetapkan Matlamat S.M.A.R.T untuk Perubahan Gaya Hidup yang Berkesan".',
      image:
        'https://abbott-dlp-sandbox.s3.ap-southeast-1.amazonaws.com/modules/images_dlp/module2/reflection/section3/q3.webp',
    },
  },
  3: {
    english: {
      text: 'Kindly proceed to watch our video on "Why Blood Sugar Monitoring is Key to Diabetes Control"',
    },
    malay: {
      text: 'Sila teruskan menonton video kami mengenai "Mengapa Pemantauan Gula Darah adalah Kunci kepada Kawalan Diabetes".',
    },
  },
  4: {
    english: {
      text: 'Thank you for completing this reflection. Kindly proceed to watch our video on how to “Eat Right, Live Well”',
    },
    malay: {
      text: 'Terima kasih kerana melengkapkan refleksi ini. Sila teruskan menonton video kami tentang cara “Eat Right, Live Well”.​',
    },
  },

  5: {
    english: {
      title: 'How do you move?',
      text: 'We understand that it can be difficult in getting or staying active due to the obstacles above. Don’t worry! Let’s figure out how to overcome those challenges so you can get “Moving More, Anytime, Anywhere” in the next video!',
      image:
        'https://abbott-dlp-sb.s3.ap-southeast-1.amazonaws.com/modules/images_dlp/module5/reflection/module5_q1_option8.webp',
    },
    malay: {
      title: 'Bagaimana anda bergerak?',
      text: 'Kami memahami bahawa ia boleh menjadi sukar untuk menjadi atau kekal aktif disebabkan rintangan-rintangan di atas. Jangan risau! Mari kita fahami bagaimana untuk mengatasi cabaran-cabaran tersebut supaya anda boleh bergerak lebih banyak, bila-bila masa, di mana-mana dalam video seterusnya!',
      image:
        'https://abbott-dlp-sb.s3.ap-southeast-1.amazonaws.com/modules/images_dlp/module5/reflection/module5_q1_option8.webp',
    },
  },
  6: {
    english: {
      text: 'Thank you for completing this reflection. Kindly proceed to watch our video on how to “Stay Motivated to Make Lasting Changes”.​',
    },
    malay: {
      text: 'Sila teruskan untuk menonton video kami tentang bagaimana anda boleh "Kekal Bermotivasi untuk Membuat Perubahan yang Berkekalan."',
    },
  },
};
// module 6 success image
// https://abbott-dlp-sandbox.s3.ap-southeast-1.amazonaws.com/modules/module5/reflection/module5_q1_option8.webp

function SectionCompletionPage({ btnColor, image, btnText, background }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.fontR);
  const { module } = useSelector((state) => state.moduleR);
  const [step, setStep] = useState(null);
  const [patientId, setPatientId] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');
    const patientId = urlParams.get('patientId');

    if (patientId) {
      setPatientId(patientId);
    }
    if (step) {
      setStep(step);
      dispatch(
        getModuleByStep({ step, language: language ? language : 'malay' })
      );
    }
  }, [dispatch, language]);

  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>

      <div className="main-body-height">
        <div className="pb-3 position-relative">
          <img
            src={module ? module.photoUrl : ''}
            style={{
              width: '100%',
              height: '12rem',
              objectFit: 'cover',
            }}
            alt="header-img"
          />

          <div className="header-text-render mx-4">
            <div className="trial-header-text mb-2">
              {module?.step < 3 ? t('Trial_Module') : t('Registered_Patient')}
            </div>
            <div className="main-header-text">
              {module && module.titleSecond}
            </div>
          </div>
        </div>

        <div className="mx-3">
          <div className="sv_progress_bar_block">
            <div className="green-background"></div>
            <div className="gray-background mx-3"></div>
            <div className="gray-background"></div>
          </div>

          <div>
            {step == '5' && (
              <h3 className="mt-3 fw-bold">
                {textJson[step][language].title}{' '}
              </h3>
            )}
            <center className="mt-5">
              {image ? (
                <img src={image} style={{ width: '12rem' }} alt="success" />
              ) : (
                <img
                  src={
                    step > 1 && textJson[step] && textJson[step][language].image
                      ? textJson[step][language].image
                      : success
                  }
                  style={{ width: '12rem', objectFit: 'contain' }}
                  alt="success"
                />
              )}
            </center>
            <div className={step == '5' ? 'py-3' : 'text-center py-3'}>
              <div className="mx-5 fw-bold">
                {step > 1 && textJson[step] ? (
                  <div>
                    {step == '6' ? (
                      <>
                        <div className="fw-bold">{t('ThankYou')}. </div>
                        {textJson[step][language].text}}
                      </>
                    ) : (
                      textJson[step][language].text
                    )}
                  </div>
                ) : (
                  <>
                    <div className="fw-bold">{t('ThankYou')}. </div>
                    <div>{t('complete_video_popup')}</div>
                  </>
                )}
              </div>

              <Link
                to={`/patient/learn_module/video?step=${step}&&patientId=${patientId}`}
              >
                <button className="custom-primary-button mt-5">
                  {step == '4' || step == '3' ? t('ContinueM') : t('Okay')}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ButtonNavBar />
      <ContactHelp />
    </div>
  );
}

export default SectionCompletionPage;
